export const getTitle = (lastDoc, currDoc) => {
  if (lastDoc) {
    if (lastDoc.Title || lastDoc.name || lastDoc.titulo)
      return lastDoc.Title || lastDoc.name || lastDoc.titulo
  }

  if (currDoc) {
    if (currDoc.Title || currDoc.name || currDoc.titulo)
      return currDoc.Title || currDoc.name || currDoc.titulo
  }
  
  return "Não Há";
}